/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { Container, jsx, Button } from "theme-ui";
import "../../css/account.css";
import Spinner from "react-bootstrap/Spinner";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";
import { validateJwtToken, getJwtTokenRoles } from "../../services/auth-service";
import { GetAllEmailTemplates } from "../../services/email-service";
import { navigate, Link } from "gatsby";

const ReportPage: React.FunctionComponent = ({ }: any) => {
  if (!validateJwtToken()) return null;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const roles = getJwtTokenRoles();
    if (!roles.includes("FullAccess") && !roles.includes("ViewReports")) {
      navigate('/401');
    }
    else {

      const fetchData = async () => {
        const templates = await GetAllEmailTemplates();
        const mappedTemplates: any[] = [];
        templates.data.map((x: any) => {
          mappedTemplates.push({
            value: x.id, label: x.name
          })
        });
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>
      <Container py="40px" sx={styles.container} >
        <div className="main-wrapper">
          <div className="header" sx={styles.header}>
            <h1>Reports</h1>
          </div>
        </div>

        <div className="wrapper">
          {!loading ? (
            <React.Fragment>
              <table
                className="table"
                sx={styles.table}
                role="table"
                id="reportTable"
              >
                <thead role="rowgroup" id="reportTable">
                  <tr role="row" id="reportTable">
                    <th>Report</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                <tbody role="rowgroup" id="reportTable">

                  <tr role="row">
                    <td role="cell">Courier VAT Report</td>
                    <td className="text-center">
                      <Link to="courier-vat">
                        <Button
                          sx={styles.buttonView}>
                          View
                        </Button>
                      </Link>
                    </td>
                  </tr>
                  <tr role="row">
                    <td role="cell">Product VAT Report</td>
                    <td className="text-center">
                      <Link to="product-vat">
                        <Button
                          sx={styles.buttonView}>
                          View
                        </Button>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          ) : (
            <span sx={styles.spinner}>
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </span>
          )}
        </div>
      </Container>      
    </PrimaryLayout>
  );
};

const styles = {
  container: {
    maxWidth: '930px!important',
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important"
    },
    header: {
      textAlign: "center",
    }
  },
  table: {
    marginTop: '40px',
    width: "100%",
    borderCollapse: "collapse",
  },
  header: {
    textAlign: "center",
  },
  buttonView: {
    float: 'right',
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "10px 20px",
    borderRadius: "10px",
    background: "#00D4C5",
    width: "100px",
    textTransform: "uppercase",
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#00D4C5",
      borderColor: "#00D4C5",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  }
};

export default ReportPage;
